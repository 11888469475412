<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ads-click</title>
    <path
      d="M9.71 15.99C6.53 15.84 4 13.22 4 10C4 6.69 6.69 4 10 4C13.22 4 15.84 6.53 15.99 9.71L13.89 9.08C13.48 7.31 11.89 6 10 6C7.79 6 6 7.79 6 10C6 11.89 7.31 13.48 9.08 13.89L9.71 15.99ZM20 10C20 10.3 19.99 10.6 19.96 10.9L17.99 10.31C18 10.21 18 10.1 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18C10.1 18 10.21 18 10.31 17.99L10.9 19.96C10.6 19.99 10.3 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10ZM16.23 14.26L20 13L10 10L13 20L14.26 16.23L18.53 20.5L20.51 18.52L16.23 14.26Z"
      fill="#6E19F0"
    />
    <path
      d="M9.71 15.99C6.53 15.84 4 13.22 4 10C4 6.69 6.69 4 10 4C13.22 4 15.84 6.53 15.99 9.71L13.89 9.08C13.48 7.31 11.89 6 10 6C7.79 6 6 7.79 6 10C6 11.89 7.31 13.48 9.08 13.89L9.71 15.99ZM20 10C20 10.3 19.99 10.6 19.96 10.9L17.99 10.31C18 10.21 18 10.1 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18C10.1 18 10.21 18 10.31 17.99L10.9 19.96C10.6 19.99 10.3 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10ZM16.23 14.26L20 13L10 10L13 20L14.26 16.23L18.53 20.5L20.51 18.52L16.23 14.26Z"
      fill="url(#paint0_linear_8060_27000)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8060_27000"
        x1="16.7453"
        y1="-4.06715e-07"
        x2="6.96883"
        y2="21.9063"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-ads-click-gradient',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
